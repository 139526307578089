import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Accordion,
  Stack,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";

import ProposeInternationalProductModal from "features/public/Products/components/Modals/ProposeInternationProductModal";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";
import { getCurrentDate } from "utils/timeUtils";

import FinalizePCMModal from "./FinalizePCMModal";
import PCMSideInfo from "./PCMSideInfo";
import PCMUpdatesSection from "./PCMUpdatesSection";

export default function PCMDetails() {
  const [showProposeModal, setShowProposeModal] = useState(false);
  const [showFinalizeModal, setShowFinalizeModal] = useState(false);
  const [availableReviewingAgents, setAvailableReviewingAgents] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const { authToken, csrfToken, currentUser } = useAuth();
  const [pcm, setPcm] = useState({});
  const [pcmPps, setPcmPps] = useState([]);
  const [pcmUpdates, setPcmUpdates] = useState([]);
  const [allPps, setAllPps] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [productPps, setProductPps] = useState([]);
  const [editedPps, setEditedPps] = useState([]);
  const { id } = useParams();

  const handlePPChange = (e) => {
    setEditedPps([...e.target.value]);
  };

  const loadAllPps = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/get_protection_profiles/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => setAllPps(response.data))
      .catch((error) => console.log(error));
  }, [id]);

  useEffect(() => {
    loadAllPps();
  }, [loadAllPps]);

  useEffect(() => {
    setEditedPps(productPps);
  }, [id, productPps]);

  useEffect(() => {
    setProductPps(
      pcmPps?.map((pp) => ({
        value: pp?.ppid.pp_id,
        label: pp?.ppid.pp_short_name,
      })),
    );
  }, [pcmPps]);

  const ppOptions = allPps?.map((pp) => ({
    value: pp.pp_id,
    label: pp.pp_short_name,
  }));

  const savePPChange = async () => {
    let newPP = editedPps.filter(
      (epp) => !productPps?.some((productPp) => productPp.value === epp.value),
    );

    let droppedPP = pcmPps.filter(
      (productPp) =>
        !editedPps?.some((epp) => productPp.ppid.pp_id === epp.value),
    );

    await Promise.all(
      newPP.map(async (pp) => {
        try {
          let data = {
            rid: pcm.rid,
            ccppid: pcm.ccid,
            ppid: pp.value,
            creator: currentUser.id,
            entrydate: getCurrentDate(),
            dropped_claim: false,
          };
          await axios.post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/pp/`,
            data,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          );
          return true;
        } catch (error) {
          setAlert({
            type: "Error updating personnel. Please try again.",
            variant: "danger",
          });
          return false;
        }
      }),
    );

    await Promise.all(
      droppedPP.map(async (pp) => {
        try {
          let data = {
            dropped_claim: true,
          };

          await axios.put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/pp/${pp.xid}`,
            data,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          );
          return true;
        } catch (error) {
          setAlert({
            type: "Error updating personnel. Please try again.",
            variant: "danger",
          });
          return false;
        }
      }),
    );

    setIsEditing(false);
    fetchPCM();
  };

  const fetchPCM = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/${id}/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setPcm(response.data);
        axios
          .get(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/pp/get_pps_by_pcm/?pcm=${response.data?.rid}`,
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            setPcmPps(response.data);
          })
          .catch((error) => setPcmPps([]));
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchPCM();
  }, []);

  useInterval(() => {
    fetchPCM();
  }, 60000); //1 min

  const fetchPcmUpdates = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/update/get_updates_by_rid/?rid=${id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setPcmUpdates(response.data);
      })
      .catch((error) => {
        console.log("Unable to get Users", error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      fetchPcmUpdates();
    }
  }, []);

  const loadNIAPActiveUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/users_by_role/?role=NIAP`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        let users = response.data
          .filter((user) => user.is_active)
          ?.map((user) => ({
            value: user.id,
            label: user?.first_name + " " + user?.last_name,
          }));
        setAvailableReviewingAgents(users);
      })
      .catch((error) => {
        console.log("Unable to get Users", error);
      });
  };

  useEffect(() => {
    loadNIAPActiveUsers();
  }, []);

  const loadPCMStatus = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/status/get_all_statuses`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setStatuses(
          response.data?.map((status) => {
            return { value: status?.stid, label: status?.status };
          }),
        );
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    loadPCMStatus();
  }, [id]);

  const saveAgentChange = (user_id) => {
    let data = {
      qc: user_id,
      moddate: new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      ),
      editor: currentUser?.id,
    };
    data["stid"] = statuses?.find(
      (status) => status?.label.toLowerCase() === "assigned",
    )?.value;
    axios
      .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/${pcm?.rid}/`, data, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
          "X-CSRFToken": csrfToken,
        },
      })
      .then((response) => {
        fetchPCM();
        setAlert({ message: "Reviewing Agent Updated!", type: "success" });
      })
      .catch((error) => {
        console.log("unable to update agent", error);
        setAlert({ message: "Error Updating Reviewing Agent", type: "danger" });
      });
  };

  const saveStatusChange = (status_id, status_name) => {
    if (status_name) {
      status_id = statuses.find(
        (status) => status.label.toLowerCase() === status_name.toLowerCase(),
      )?.value;
    }

    if (status_name === "Posted") {
      handleShowFinalizeModal();
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/${pcm?.rid}/`,
          {
            stid: status_id,
            moddate: new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ),
            editor: currentUser?.id,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              "X-CSRFToken": csrfToken,
            },
          },
        )
        .then((response) => {
          setAlert({ message: "Status Updated!", type: "success" });
          fetchPCM();
          if (status_name.toLowerCase() === "ready to be posted") {
            handleSendFormEmail();
          }
        })
        .catch((error) => {
          console.log("unable to update status", error);
          setAlert({ message: "Error Updating PCM.", type: "danger" });
        });
    }
  };

  const handleSendFormEmail = () => {
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}mailer/send_alert_email/`,
        {
          recipients: { to: ["NIAP Management"], cc: [] },
          subject: `PCM Request - “PCM-${moment().format("YYYY")}-${pcm.ticket}" Ready to be Posted`,
          reason_for_alert: "Ready For Post",
          product_id: pcm.rid,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then(() => {
        console.log("alert email sent");
      })
      .catch((error) => {
        console.log("email error", error);
      });
  };

  const handleShowProposeModal = () => {
    setShowProposeModal(!showProposeModal);
  };

  const handleShowFinalizeModal = () => {
    setShowFinalizeModal(!showFinalizeModal);
  };

  return (
    <Container className="py-5" fluid>
      <ProposeInternationalProductModal
        showModal={showProposeModal}
        handleShowModal={handleShowProposeModal}
        setAlert={setAlert}
        refetch={fetchPCM}
        pcm={Object.keys(pcm)?.length > 0 ? pcm : null}
      />
      <FinalizePCMModal
        show={showFinalizeModal}
        handleShow={handleShowFinalizeModal}
        pcm={pcm}
        statuses={statuses}
        refetch={fetchPCM}
        setAlert={setAlert}
        pcmPps={pcmPps}
      />
      <Row>
        <Col sm={9}>
          <Row>
            {alert.message !== "" && (
              <Row className="d-flex justify-content-center">
                <Col>
                  <Alert
                    variant={alert.type}
                    dismissible
                    onClose={() => setAlert({ message: "", type: "" })}
                  >
                    {alert.message}
                  </Alert>
                </Col>
              </Row>
            )}
          </Row>
          <Row>
            <Col sm={8}>
              <h3 className="text-primary">PRODUCT CONCURRENCE MANAGEMENT:</h3>
            </Col>
            <Col sm={2}></Col>
            <Row>
              <Col sm={5} className="mt-3 mb-3">
                <Row>
                  <h3 className="fw-bold text-primary">
                    Details for{" "}
                    <span style={{ color: "var(--bs-blue)" }}>
                      {`PCM-${moment.utc(pcm?.entrydate).format("YYYY")}-${pcm?.ticket}`}
                    </span>
                  </h3>
                </Row>
                {pcm?.maintenance_id !== null && (
                  <Row>
                    <h3 className="fw-bold text-secondary">
                      Maintenance Release
                    </h3>
                  </Row>
                )}
              </Col>
              <Col sm={5} className="mt-3 mb-3 border-start border-secondary">
                <h3 className="me-3 fw-bold text-primary">{pcm?.prod}</h3>
              </Col>
            </Row>
            <Col className="border-dark border-bottom border-top d-flex">
              <Stack className="border-start border-secondary ps-2 my-2">
                <h5>Status</h5>
                <h5 className="text-secondary">
                  {pcm?.stid?.status === "Posted" ? (
                    <a href={`/products/international-product/${pcm?.ccid}`}>
                      {pcm?.stid?.status}
                    </a>
                  ) : (
                    <Select
                      onChange={(e) => saveStatusChange(e.value, e.label)}
                      defaultValue={statuses.find(
                        (status) => status.value === pcm?.stid?.stid,
                      )}
                      value={
                        pcm?.stid?.status
                          ? statuses.find(
                              (status) => status.value === pcm?.stid?.stid,
                            )
                          : ""
                      }
                      options={statuses}
                      className="small-font"
                    />
                  )}
                </h5>
              </Stack>
            </Col>
            <Col className="border-dark border-bottom border-top d-flex">
              <Stack className="border-start border-secondary ps-2 my-2">
                <Row>
                  <Col sm={10}>
                    <h5>PP Claim</h5>
                  </Col>
                  <Col sm={2}>
                    {isEditing ? (
                      <SaveIcon
                        style={{ cursor: "pointer", float: "right" }}
                        onClick={savePPChange}
                      />
                    ) : (
                      <EditIcon
                        style={{ cursor: "pointer", float: "right" }}
                        onClick={() => setIsEditing(true)}
                      />
                    )}
                  </Col>
                </Row>
                {!isEditing ? (
                  pcmPps.map((pp, idx) => (
                    <div key={idx}>
                      {pp?.ppid?.archived && (
                        <p className="mb-0 text-danger">
                          {moment
                            .utc(pp?.ppid?.sunset_date)
                            .format("MM/DD/YYYY")}
                          <WbTwilightIcon size="xs" />
                        </p>
                      )}
                      <p className="text-secondary">{pp?.ppid?.pp_name}</p>
                    </div>
                  ))
                ) : (
                  <>
                    <Select
                      isMulti
                      options={ppOptions}
                      onChange={(e) =>
                        handlePPChange({
                          target: { name: "protection_profile", value: e },
                        })
                      }
                      defaultValue={() =>
                        ppOptions?.filter((profile) =>
                          productPps.some(
                            (productpp) => productpp.value === profile.value,
                          ),
                        )
                      }
                    />
                  </>
                )}
              </Stack>
            </Col>
            <Col className="border-dark border-bottom border-top d-flex">
              <Stack className="border-start border-secondary ps-2 my-2">
                <h5>Posting Agent</h5>
                <h5 className="text-secondary">
                  {pcm?.agent !== null
                    ? pcm.agent?.first_name + " " + pcm.agent?.last_name
                    : "---"}
                </h5>
              </Stack>
            </Col>
            <Col className="border-dark border-bottom border-top d-flex">
              <Stack className="border-start border-secondary ps-2 my-2">
                <h5>Reviewing Agent</h5>
                <h5 className="text-secondary">
                  <Select
                    id="reviewing_agent"
                    onChange={(e) => saveAgentChange(e.value)}
                    defaultValue={availableReviewingAgents.find(
                      (user) => user.value === pcm.qc?.id,
                    )}
                    value={
                      pcm?.qc
                        ? availableReviewingAgents.find(
                            (user) => user.value === pcm.qc?.id,
                          )
                        : ""
                    }
                    options={availableReviewingAgents}
                    className="small-font"
                  />
                </h5>
              </Stack>
            </Col>
            {pcm?.stid?.status !== "Posted" &&
              pcm?.stid?.status !== "Rejected" && (
                <Col className="border-dark border-bottom border-top d-flex">
                  <Stack className="border-start border-secondary ps-2 my-2">
                    <Button
                      variant="warning rounded-pill mb-3"
                      onClick={() => setShowProposeModal(true)}
                    >
                      Edit PCM
                    </Button>
                    {pcm?.stid?.status.toLowerCase() ===
                      "ready to be posted" && (
                      <Button
                        variant="success rounded-pill mb-3"
                        onClick={() => setShowFinalizeModal(true)}
                      >
                        Finalize PCM Request
                      </Button>
                    )}
                    {pcm?.stid?.status.toLowerCase() !==
                      "ready to be posted" && (
                      <Button
                        variant="success rounded-pill mb-3"
                        onClick={() =>
                          saveStatusChange(false, "Ready to be Posted")
                        }
                      >
                        Ready to be Posted
                      </Button>
                    )}
                  </Stack>
                </Col>
              )}
          </Row>
          <Row>
            <Accordion alwaysOpen>
              <Accordion.Item>
                <Accordion.Header>
                  <h6 className="fw-bold text-primary mt-3">
                    ADDITIONAL INFORMATION
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  {pcm?.additional_information &&
                    parse(pcm?.additional_information)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
          <Row>
            <PCMUpdatesSection
              pcmUpdates={pcmUpdates}
              pcm={pcm}
              refetch={fetchPcmUpdates}
            />
          </Row>
        </Col>
        <Col>
          <PCMSideInfo pcm={pcm} fetchPCM={fetchPCM} />
        </Col>
      </Row>
    </Container>
  );
}
