import { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";

import { usePpDropdownOptionsQuery } from "api/memberPortal/project/protectionProfiles/protectionProfiles.api";
import ReactSelect from "components/ReactSelect";

export default function PPFormSection({
  editedProduct,
  handleChange,
  ppValid,
  productPps,
}) {
  const [ppObj] = useState({});

  const { data: allPps = [] } = usePpDropdownOptionsQuery();

  const ppOptions = allPps
    .filter(
      (pp) => !productPps?.some((productPp) => productPp.value === pp.value),
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Row>
      <Form.Label>Protection Profile Claims:*</Form.Label>
      <Form.Group controlId="claim" name="protection_profile">
        <Row>
          <Col>
            <ReactSelect
              isMulti={true}
              options={ppOptions}
              newObj={
                !editedProduct?.protection_profile ? ppObj : editedProduct
              }
              handleChange={handleChange}
              name="protection_profile"
              isValid={ppValid}
              defaultValue={productPps}
              id="checkin-form-pp-select"
            />
          </Col>
        </Row>
      </Form.Group>
    </Row>
  );
}
